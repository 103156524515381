import React, { useEffect, useState } from 'react'
import TimeLineListItem from './TimeLineListItem';
import CardHeader from '../card/CardHeader';
import {SlEvent} from 'react-icons/sl'
import { useCurrent, useLogs } from '../../hooks';

const TimeLine = () => {
    const {currentLangId} = useCurrent();
    const {resultLogsFetch,loadingLogsFetch,errorLogsFetch} = useLogs();
    const [timeLineArray,setTimeLineArray]  = useState([]);

    const timeLineList = timeLineArray?.map((timeLine) => (
        <TimeLineListItem 
        key={timeLine._id}
        msg = {currentLangId === 'en' ? timeLine.logContentEn : timeLine.logContentAr} 
        userName = {timeLine.userName} 
        dateTime={new Date(timeLine.createdAt).toUTCString()} />
    ));


    useEffect(()=>{
        if(resultLogsFetch){
            setTimeLineArray(resultLogsFetch);
        }
    },[resultLogsFetch]);

    return (
        <>
        <div className='flex-1  flex flex-col   pt-3   border-slate-200 rounded-lg bg-slate-50 '>
            {
                timeLineArray.length > 0 && <div>
                    <CardHeader>الاحداث الاخيرة </CardHeader>
                                <div className='relative flex-1 '>
                                    {timeLineArray.length > 0 ? 
                                        <div className='absolute w-full min-h-56 max-h-80 pb-2 overflow-y-auto flex flex-col items-bottom'>
                                            {timeLineList}
                                        </div> : <div className='flex flex-col items-center justify-center h-full gap-2 text-sm font-bold text-slate-300 p-5' >
                                            <SlEvent size={18}/>
                                            <span >لا يوجد احداث </span>
                                            </div>
                                    }
                                </div>
                </div>
            }
            
        </div>
        </>
    )
}

export default TimeLine