import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';
import {Doughnut } from 'react-chartjs-2'
import CardHeader from '../card/CardHeader';
import {  MwSpinnerButton } from '../ui';
import { useInvoice } from '../../hooks';


ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
)

const DaugeChart = ({labels,labelMetrics ,datasetLabel ,datasetData, className,datasetDataLabel ,loading=true}) => {
    const {formatter} = useInvoice();
    const data = {
        labels: labels,
        datasets:[
            {
                label:datasetLabel ,
                data:datasetData,
                borderColor: ['indigo','gray'],
                backgroundColor: ['indigo','gray'],
                tension:0.4,
                circumference:180,
                rotation:270
            }
        ]
    }
    const options = {
        plugins: {
            // defaultFontFamily:'"Tajawal", sans-serif',
            legend: {
                labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: 14,
                        family: '"Tajawal", sans-serif',
                    }
                }
            }
        }
    }

    return ( 
            <div className={` justify-between items-between px-3 pb-2 border-2  rounded-lg border-slate-100 bg-slate-50 ${className}`}>
                <CardHeader>{datasetLabel}</CardHeader>
                <div className='text-center text-slate-400 font-bold'>{formatter.format(labelMetrics)}</div>
                { !loading ? <div>
                    <Doughnut 
                        data={data}
                        options={options}
                    >
                    </Doughnut>
                    <div className='flex flex-row-reverse justify-between  items-between w-full text-slate-400'>
                        { datasetDataLabel?.map((item,index) => (
                            <div key={index} >
                                <span className='text-xs font-bold '>{ !isNaN(parseFloat(item)) ? formatter.format(item) : '0' }</span>
                            </div>
                        ))}
                    </div> 
                </div> : <MwSpinnerButton withLabel={false} isFullCenter={true} /> }
            </div>
    )
}

export default DaugeChart