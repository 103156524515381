import React from 'react'

const CardHeader = ({children}) => {
    return (
        <div 
            className='text-xs font-bold text-slate-400 border-b w-full mt-3 mb-1'>
                {children}
        </div>
    )
}

export default CardHeader