import React from 'react'

const TimeLineListItem = ({msg,userName,dateTime}) => {
    return (
        <div className='p-1 pb-2 px-2'>
            <div className='relative flex items-center  '>
                <span className='absolute start-2 top-9 w-0.5 h-16  bg-slate-300'></span>
                <span className='w-5 h-5 border-4 rounded-full bg-indigo-800 border-slate-300 me-1'></span>
                <div className=' w-full bg-slate-100 rounded-lg border px-2 ms-1 text-xs'>
                    <div className=' text-slate-300 text-end'> {dateTime}</div>
                    <div className=' text-slate-500'>{msg}</div>
                    <div className=' flex flex-col justify-between  '>
                        <div className='text-slate-300'>{userName}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimeLineListItem