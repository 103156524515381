import React, { useState,useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {  useTranslation } from 'react-i18next';
import {AiOutlineSave} from 'react-icons/ai'
import {MdOutlineAddBox} from 'react-icons/md'

import {updateSettings} from '../../../store/settingSlice'
import {useUser,useInvoice,useCurrent, usePut,useLogs} from '../../../hooks';
import {MwToast, MwButton} from '../../ui';
import CreateProduct from '../../Products/CreateProduct';
import CreateCustomer from '../../Customers/CreateCustomer';
import CreateSalesman from '../../Salesmans/CreateSalesman';
import {getAllSalesman} from '../../../services/salesmanService';
import {getAllCustomers} from '../../../services/customerService';
import MwSpinnerButton from '../../ui/MwSpinnerButton';
import SideModal from '../../ui/SideModal';

import InvFormFooter from './InvFormFooter';
import InvFormHeader from './InvFormHeader';
import InvFormBody from './InvFormBody';
import { amountFormat } from '../../../utils/invoceHelper';


const InvFormUpdate = ({tenantId,tenantUsername,invData}) => {
    const [t] = useTranslation('global')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disabledDiscount,setDisabledDiscount] = useState(false);

    const {currentLangId} = useCurrent();
    const companySelected  = useSelector((state)=>state.currentCompany)
    const branchSelected = useSelector((state)=>state.currentBranch)
    const {getUser} = useUser();
    const {
            initalProductItem,
            SETTING_INVOICE,
            calcSumPrice,
            calcSumNetTotal,
            toFloat,
            calcVat,
            formatter
            } = useInvoice();
    const INV_CURRENCY = SETTING_INVOICE?.INV_CURRENCY
    const SETTING_INV_CURRENCY = INV_CURRENCY || 'SAR'

    const {resultLogsPost,loadingLogsPost,errorLogsPost,postDataHandler} = useLogs()

    // products
    const [reloadProductList,setReloadProductList] = useState(false)
    const [showProductModal,setShowProductModal] = useState(false);
    // Customer
    const [customers,setCustomers] = useState([]); 
    const [showCreateCustomerModal,setShowCreateCustomerModal] = useState(false);
    const [isAddedCustomer,setIsAddedcustomer] = useState(false); 
    // Salesman
    const [salesmans,setSalesmans] = useState([]); 
    const [showCreateSalesmanModal,setShowCreateSalesmanModal] = useState(false);
    const [isAddedSalesman,setIsAddedSalesman] = useState(false); 
    // Toast
    const [showToast,setShowToast] = useState(false);
    const [msgToast,setMsgToast] = useState('');
    
    const { data:invDataPost, loading:invLoadingPost, error:invErrorPost,putData:invPut} = usePut(); 
    const  [formErrors,setFormErrors] = useState({});
    // Start Invoice input fields 
    //console.log(invData)
    const productDetails = invData?.quote_details.map((e,index)=>{
        return {
            // id:e.id,
            index:index,
            productId:e.product_id, 
            productName:e.product_name,
            qty:e.qty,
            price:e.total_price,
            priceUnit: e.price,
            productDiscount: e.product_discount,
            productNetTotal: e.product_net_total,
            productVat:e.product_vat,
            productNetTotalWithVat:e.product_net_total_with_vat
        }
        });
    const [invNo,setInvNo] = useState(invData?.inv_no);
    const invRef = useRef(invData?.inv_ref || '');
    const invDate = useRef(invData?.inv_date);
    const invDateDue = useRef(invData?.inv_date_due);
    const invCurrency = useRef();
    const [customerSelected,setCustomerSelected] = useState({ id:invData?.customer_id,label:invData?.customer_name});
    const [salesmanSelected,setSalesmanSelected] = useState({ id:invData?.salesman_id,label:invData?.salesman_name});
    const [invNotes,setInvNotes] = useState(invData?.notes || '');
    const [havePromoCode,setHavePromoCode] = useState(false);
    const [promoCode,setPromoCode] = useState('');
    const [invTotalAmount,setInvTotalAmount] = useState(invData.total_amount);
    const [totalAmountWithDiscount,setTotalAmountWithDiscount] = useState('');
    const [discount,setDiscount] = useState(invData.discount_amount)
    const [vat,setVat] = useState(invData?.vat_amount)
    const [netTotal,setNetTotal] = useState(invData?.net_amount)
    const [productList,setProductList] = useState(initalProductItem); 
    const [tagList,setTagList] = useState(JSON.parse(invData?.inv_tags));
    ////////////////////////////////////////////////
    
    const initalCustomerName = invData?.customer_name || '';
    const initalSalesmanName = invData?.salesman_name || '';
    // Promo Code
    const promoCodeHandler =() => {

    }
    // Product Functions
    const showModalProductHandle = () => {
        setShowProductModal(true);
    }
    const onProductCreated = () => {
        setReloadProductList(true);
    }

    // Customer Functions
    const onCustomerCreated = () => {
        setIsAddedcustomer(true);
        
        // setMsgToast('Customer Has been added...')
        // setShowToast(true);
    }
    const onClickNewCustomer = () => {
        setShowCreateCustomerModal(true);
        setIsAddedcustomer(false);
    }
    


    // Salesman Functions
    const onSalesmanCreated = () => {
        setIsAddedSalesman(true);
        // setMsgToast('Salesman Has been added...')
        // setShowToast(true);
    }
    const onClickNewSalesman = () => {
        setShowCreateSalesmanModal(true);
        setIsAddedSalesman(false);
    }
    const calcSum = () => {
        return calcSumPrice(productList)
        
    }

    // const invSumHandler = () => {
    //     const sum = calcSum();
    //     const _totalAmountWithDiscount =  sum - discount;
    //     setInvTotalAmount(sum);
    //     setTotalAmountWithDiscount(_totalAmountWithDiscount);
    //     vatHandler(_totalAmountWithDiscount);
    // }
    const invSumHandler = () => {
        console.log('invSumHandler')
        const sum = calcSum();
        const netTotal = calcSumNetTotal(productList);
        const _discountItems = sum - netTotal;
        
        _discountItems > 0 ? setDisabledDiscount(true) : setDisabledDiscount(false)
        if(disabledDiscount && _discountItems >= 0){
            setDiscount(amountFormat(_discountItems) ) 
        }
        const _totalAmountWithDiscount = sum - discount;
        setInvTotalAmount(sum);
        setTotalAmountWithDiscount(_totalAmountWithDiscount);
        vatHandler(_totalAmountWithDiscount);
        console.log('total => ',invTotalAmount,totalAmountWithDiscount,)
    }
    const vatHandler = (total=totalAmountWithDiscount) => {
        const calcvat = calcVat(total)
        setVat(calcvat)
        
        const nt = toFloat(calcvat,false) + toFloat(total,false)
        setNetTotal(nt)
        
    }

    const discountHandler = () => {
        invSumHandler();
    }

    const netTotalHandler = () => {
        const invTotal  = toFloat(invTotalAmount,false)   ;
        const invDiscount = toFloat(discount,false) ;  ;
        const invVat = toFloat(vat,false) ;  ;
        const net = toFloat(invTotal - invDiscount + invVat);
        setNetTotal(net);
    }

    const discountOnchange = (value) => {
        
        const sum = calcSum();
        let discountValue;
        if(value.includes('%')){
            invSumHandler();
            discountValue =  (toFloat( toFloat(sum,false) * toFloat(value.replace('%', ''),false) /100 ));
            console.log(discountValue);
        }else{
            discountValue = value
        }
        setDiscount(()=> discountValue );
    }
    const validateForm = () => {
        setFormErrors({})
        let errors = {};
        if(!invDate?.current.value){
            errors.invDate = 'Date is required';
        }
        if(!invDateDue?.current.value){
            errors.invDateDue = 'Date Due is required';
        }
        if(!!productList[0].productId === false){
            errors.productList = 'you must select a product';
        }

        if(Object.keys(errors).length > 0){
            setFormErrors({...errors});
            return false;
        }
        return true;
    }
    const onsubmitHandler = (e) => {
        if(e.key === 'Enter') return ;
        e.preventDefault()
        // validate form 
        if(!validateForm())return;
        
        let productListHandler = [];
        productList.map((product)=>{
            if (product && product.productName !=='Deleted' ){
                productListHandler.push(  {
                    id: product.id,
                    product_id : product.productId,
                    product_name : product.productName,
                    unit_id : 1,
                    qty : product.qty ,
                    price: (parseFloat(product.price)/parseInt(product.qty)),
                    total_price : product.price,
                    product_discount : product.productDiscount || 0,
                    product_net_total : product.productNetTotal,
                    product_vat : product.productVat,
                    product_net_total_with_vat: product.productNetTotalWithVat
                });
            }
        });

        const _data = {
            inv_no: invNo,
            inv_ref:invRef?.current.value,
            inv_date:invDate?.current.value,
            inv_date_due:invDateDue?.current.value,
            customer_id:customerSelected?.value,
            customer_name:customerSelected?.label,
            salesman_id:salesmanSelected?.value,
            salesman_name:salesmanSelected?.label,
            total_amount: invTotalAmount,
            discount_amount: discount,
            // promo_code_id:
            // promo_code:
            vat_amount: vat,
            net_amount: netTotal,
            notes: invNotes,
            is_paid: false,
            type_id:1,
            status_id:1,
            tenant_id: tenantId,
            company_id: companySelected.value,
            branch_id: branchSelected.value,
            created_by: getUser?._id || 0,
            inv_tags:JSON.stringify(tagList),
            inv_details:productListHandler
        }
        invPut(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/quotes/update/${invData.id}`,_data)
    };

    const getcustomerHandler = async () => {
        const res = await getAllCustomers(tenantId,companySelected.value,branchSelected.value)
        setCustomers(res);
    }

    const getSalesmanHandler = async () => {
        const res = await getAllSalesman(tenantId,companySelected.value,branchSelected.value);
        setSalesmans(res);
    }
    const setLogsHandler =  () => {
        const _data = {
            moduleName: "SALES",
            resourceName: "QUOTES",
            eventName:"UPDATE",
            logContentEn: `Update quote No: ${invDataPost.inv_no}`,
            logContentAr: `تعديل عرض سعر برقم : ${invDataPost.inv_no}`,
        }
        postDataHandler(_data);
    }

    useEffect(()=>{
        if(resultLogsPost)
        navigate(`/${tenantUsername}/quotes`);
    },[resultLogsPost]);

    useEffect(()=>{
        if(invDataPost){
            setLogsHandler();
        }
    },[invDataPost]);

    useEffect(() => { 
        setProductList([...productDetails])
        getcustomerHandler();    
        getSalesmanHandler();
        console.log('invData',invData,productList)  
    },[]);

    useEffect(() => {
        if(isAddedCustomer){
            getcustomerHandler();        
        }
    },[isAddedCustomer]);

    useEffect(() => {
        if(isAddedSalesman){
            getSalesmanHandler();       
        }
    },[isAddedSalesman]);

    useEffect(()=>{
        invSumHandler()
        dispatch(updateSettings({
            typeSetting : 'invoice', value :{
                INV_ITEMS_ROW_NUMBER : productList.length + 1
            }
        }))
    },[productList]);

    useEffect(()=>{
        netTotalHandler();
        vatHandler();
    },[invTotalAmount])

    useEffect(() => {
        discountHandler();
    },[discount])




    return (
        <>
            { showToast && <MwToast 
                msg={msgToast} 
                isShow={showToast}
                setIsShow = {setShowToast}
                /> 
            }

            <SideModal title="Create New Product" onShow={showProductModal} setOnShow={setShowProductModal} >
                <CreateProduct onProductCreated={onProductCreated} setReloadProductList={setReloadProductList}  />
            </SideModal>

            <SideModal title="Create New Customer" onShow={showCreateCustomerModal} setOnShow={setShowCreateCustomerModal} >
                <CreateCustomer onCustomerCreated={onCustomerCreated}  />
            </SideModal>

            <SideModal title="Create New Salesman" onShow={showCreateSalesmanModal} setOnShow={setShowCreateSalesmanModal} >
                <CreateSalesman onSalesmanCreated={onSalesmanCreated}  />
            </SideModal>
            
            {
            <form  onSubmit={onsubmitHandler} >
                <div className='flex items-center justify-between mb-3 mt-1' >
                    <div className='pb-2 text-gray-400 text-sm flex items-center gap-1 '>
                        <MdOutlineAddBox size={18}/>
                        تعديل عرض سعر رقم {invData?.inv_no}
                    </div>
                    { invLoadingPost ? <MwSpinnerButton/> : 
                        <MwButton
                        actionType='submit'
                        inGroup = {true}
                                type = 'saveBtn'
                            > <AiOutlineSave size={16}/>  حفظ عرض السعر  </MwButton> 
                    }
                </div>
                <div  className='flex flex-col h-full justify-between  bg-slate-50 p-3 rounded-lg'>
                    <InvFormHeader
                            invNo = {invNo}
                            setInvNo = {setInvNo}
                            invRef = {invRef}
                            invDate = {invDate}
                            invDateDue = {invDateDue}
                            invCurrency = {invCurrency}
                            customers = {customers}
                            onClickNewCustomer = {onClickNewCustomer}
                            salesmans = {salesmans}
                            onClickNewSalesman = {onClickNewSalesman}
                            customerSelected = {customerSelected} 
                            setCustomerSelected = {setCustomerSelected}
                            salesmanSelected = {salesmanSelected}
                            setSalesmanSelected = {setSalesmanSelected}
                            formErrors = {formErrors}
                            initalCustomerName = {initalCustomerName}
                            initalSalesmanName = {initalSalesmanName}
                    />
                    <InvFormBody 
                        initalProductItem = {initalProductItem}
                        reloadProductList = {reloadProductList}
                        showModalProductHandle = {showModalProductHandle}
                        productList = {productList}
                        setProductList ={setProductList}
                        formErrors = {formErrors}
                        /> 
                    <InvFormFooter
                        invNotes = {invNotes}
                        setInvNotes = {setInvNotes}
                        invTotalAmount = {invTotalAmount}
                        totalAmountWithDiscount = {totalAmountWithDiscount} 
                        invCurrency = {invCurrency}
                        promoCode = {promoCode}
                        setPromoCode = {setPromoCode}
                        discount = {discount}
                        vat = {vat}
                        netTotal = {netTotal}
                        havePromoCode = {havePromoCode}
                        setHavePromoCode = {setHavePromoCode}
                        promoCodeHandler = {promoCodeHandler}
                        discountOnchange = {discountOnchange}
                        SETTING_PRICE_INCLUDE_VAT = {SETTING_INVOICE.PRICE_INCLUDE_VAT}
                        SETTING_INV_CURRENCY = {SETTING_INV_CURRENCY}
                        tagList = {tagList} 
                        setTagList = {setTagList}
                    />
                </div>
            </form> }
        </>
    )
}

export default InvFormUpdate