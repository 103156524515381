import React, { useEffect } from 'react'
import {  useNavigate, useParams } from 'react-router-dom'
import parse from 'html-react-parser';
import InvListBasicZatcaItem from './InvListBasicZatcaItem';
import {  MwButton, MwSpinnerButton, NoDataFound } from '../../ui'
import { useInvoice, useZatca } from '../../../hooks';

const InvListBasicZatca = ({
    data,
    loading=true,
    links=[],
    refreshHandler = ()=>{},
    paginateHandler = (url)=>{} ,
    withBorder=true,
    withSummary=false,
    checkList,
    setCheckList,
    url
}) => {
    const params = useParams();
    const navigate = useNavigate();
    const {formatter} = useInvoice()
    const {zatcaRespose,storeResult,loading:zatcaLoading,setLoading:setZatcaLoading,submited:zatcaSubmited,zatcaInvId,sendToZatca} = useZatca();
    const handleSelectAll = (event)=>{
        console.log(event)
        setCheckList([]);
        const _ids = []
        if(event.target.checked){

            data.map((item) => {
                _ids.push(item)
            });
        }
        setCheckList([..._ids]);
        console.log(checkList)
    }
    const sendAllToZatca = () => {
        setZatcaLoading(true);
        console.log(checkList)
        
        var i=0;
        checkList.forEach(item => {
            i++;
            
            setTimeout(()=>{
                sendToZatca(item,item.id);
                const newlist = checkList.filter(el => el.id !== item.id);
                console.log(newlist);
                setCheckList([...newlist]);

            },5000*i);

            setTimeout(()=>{
                refreshHandler()
                setCheckList([]);
            },5000*i*(checkList.length+1));
            
        });
    }
    useEffect(() => {
        console.log('storeResult => ',storeResult,'zatcaRespose => ',zatcaRespose)
        //refreshHandler()
    },[storeResult])

    return (
        <>

            {!loading ? 
            <div className={`flex-1 ${ withBorder && ' border rounded-xl ' }` }>
                { data?.length > 0 ? <ul>
                    <li className={`flex gap-2 justify-between p-3 text-xs border-b bg-slate-100 `}>
                        <div className='w-16  text-center'>كود الفرع</div>
                        <div className='w-24  text-center'>رقم الفاتورة</div>
                        <div className='flex-1'>اسم العميل</div>
                        <div className='w-24   text-center'>مبلغ الفاتورة</div>
                        { !withSummary && <div className='w-32 px-1  text-center'>تاريخ الفاتورة</div> }
                        { <div className='w-36 px-1  text-center'> نوع الفاتورة Zatca </div>}
                        { <div className='w-24 px-1  text-center'> حالة Zatca </div>}
                        { <div className='flex-1 px-1  text-center'>  اجراءات Zatca </div>}
                        <div className='px-2'><input type='checkbox'  onChange={(event)=>handleSelectAll(event)}  /></div>
                    </li>
                    <div className={ ` ${ !withSummary && 'h-[calc(100vh-350px)]'} overflow-auto pb-3`}>
                        {data.map((item,index) => (
                            <InvListBasicZatcaItem 
                            checkList = {checkList} setCheckList ={setCheckList}
                                key={index}
                                item = {item}
                                index = {index}
                                withSummary = {withSummary}
                                formatter = {formatter}
                                dataLength = {data?.length}
                                zatcaLoading = {zatcaLoading}
                                setZatcaLoading = {setZatcaLoading}
                                zatcaSubmited = {zatcaSubmited}
                                zatcaInvId = {zatcaInvId}
                                sendToZatca = {sendToZatca}
                                />))}
                    </div>
                    { 
                        links.length > 0 &&
                            <div id="dataLinks" className="flex border-t pt-2 pb-1 px-5 bg-slate-100">
                            <div className='flex-1 list-none  flex gap-3  cursor-default'>

                                {
                                    links.length > 3 && 
                                    links.map((item,i) => <li key={i} className={`hover:font-bold p-1 ${url === item?.url?.replace('http','https') ? 'bg-green-100 w-5 h-5  flex justify-items-center	 justify-center font-bold rounded-full ' : '' }`} onClick={()=>item?.url && paginateHandler(item?.url.replace('http','https'))} >{ parse(item.label)}</li>)
                                }
                            </div>
                            <div className='w-18'>
                            {  zatcaLoading  ? <MwSpinnerButton label='Sending to ZATCA...' /> : <MwButton size="sm" disabled={ checkList.length === 0 } onClick={()=>sendAllToZatca()} type='secondary'  > SEND ALL TO ZATCA</MwButton> }
                            </div>
                            </div> 
                    }
                </ul> : <NoDataFound msg='لا توجد فواتير ' />  }
            </div> : <div className='flex-1'>
                        <MwSpinnerButton withLabel={false} isFullCenter={true} />
                    </div> }
        </>
    )
}

export default InvListBasicZatca