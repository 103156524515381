
import { Routes ,Route } from "react-router-dom";
import withTenant from './utils/withTenant';
import { useState } from "react";
import { useSelector } from "react-redux";

import ManageInvoices from "./pages/invoices/ManageInvoices";
import CreateInvoice from "./pages/invoices/CreateInvoice";
import EditInvoice from "./pages/invoices/EditInvoice";
import EditQuote from "./pages/quotes/EditQuote";
import CreateQuote from "./pages/quotes/CreateQuote";
import ManageQuotes from "./pages/quotes/ManageQuotes";
import NotFound from "./NotFound";
import TokenRedirect from "./pages/TokenRedirect";
import Logout from "./pages/Logout";
import HeaderNavBar from "./components/layout/HeaderNavBar";
import Footer from "./components/layout/Footer";
import MenuSide from "./components/layout/MenuSide";
import DashBoard from "./pages/DashBoard";
import ShowInvoice from "./components/invoice/ShowInvoice";
import ShowQuote from "./components/quote/ShowInvoice";
import withGuard from "./utils/withGuard";
import { useTenant,useStartup } from "./hooks";
import { MwSpinner } from "./components/ui";
import Settiings from "./pages/Settiings";
import Customers from "./pages/Customers";
import CreateCustomer from "./components/Customers/CreateCustomer";
import ShowCustomer from "./components/Customers/ShowCustomer";
import Salesmans from "./pages/Salesmans";
import CreateSalesman from "./components/Salesmans/CreateSalesman";
import Products from "./pages/products/Products";
import CreateProduct from "./components/Products/CreateProduct";
import ProductsHome from "./pages/products/ProductsHome";
import EditSalesman from "./components/Salesmans/EditSalesman";
import Reports from "./pages/Reports";
import CreateReturnsInvoice from "./pages/invoices-returns/CreateReturnsInvoice";
import ShowInvoiceReturns from "./components/invoice-returns/ShowInvoiceReturns";
import Transactions from "./pages/transactions/Transactions";
import ManageInvoicesZatca from "./pages/invoices/ManageInvoicesZatca";

function App() {
  const [openMenu,setOpenMenu] = useState(false);
  const langState = useSelector((state) => state.lang);
  const {tenant,tenantUsername,loading:loadingTenant,companiesList,branchesList,tenantId,companySelected,branchSelected} = useTenant();
  const {startup} = useStartup();
  return (
    !loadingTenant && startup ? <div dir={langState?.value === 'ar' ? 'rtl' : 'ltr'} className="h-screen flex flex-col flex-between  ">
      <HeaderNavBar
        companySelected = {companySelected}
        branchSelected = {branchSelected}
        companiesList = {companiesList}
        branchesList = {branchesList}
        tenantUsername = {tenantUsername}
        tenant = {tenant}
        openMenu = {openMenu}
        setOpenMenu = {setOpenMenu}
      />
      <div className="bg-white flex  flex-1   m-auto  w-full">
        <MenuSide
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          />
        <div className={`flex flex-col flex-1 px-2 py-1 pe-3   primary-bg-color ${openMenu ? 'ms-8 md:ms-0 ps-3' :'md:ms-10'} `}>
          <div className="p-5 rounded-2xl bg-slate-50 min-h-[calc(100vh-90px)] max-h-[calc(100vh-90px)] overflow-auto">
          <Routes >
              <Route path=":tenant/"   element={ <DashBoard/>} />
              <Route path=":tenant/dashboard"   element={ <DashBoard/>} />
              <Route path=":tenant/token/:token" element={<TokenRedirect/>} />
              <Route path=":tenant/invoices"   element={<ManageInvoices/> } />
              <Route path=":tenant/invoices-zatca"   element={<ManageInvoicesZatca/> } />
              <Route path=":tenant/invoices/show/:id/:companyId/:branchId"   element={!loadingTenant && <ShowInvoice/> } />
              <Route path=":tenant/invoices/print/:id/:companyId/:branchId"   element={!loadingTenant && <ShowInvoice isPrint={true} /> } />
              <Route path=":tenant/invoices/delivery/:id/:companyId/:branchId"   element={!loadingTenant && <ShowInvoice isPrint={true} isDelivery={true} /> } />
              <Route path=":tenant/settings"   element={!loadingTenant && <Settiings /> } />
              <Route  
                path=":tenant/invoices/create"  
                element={  
                  <CreateInvoice 
                  tenantId = {tenantId}
                  tenantUsername ={tenantUsername}
                  companySelected = {companySelected}
                  branchSelected = {branchSelected}
                  /> } 
                />
              <Route  
                path=":tenant/invoices/edit/:id"  
                element={  <EditInvoice /> } 
                />
              <Route  
                path=":tenant/invoices-returns/:id"  
                element={  <CreateReturnsInvoice /> } 
                />
              <Route path=":tenant/invoices-returns/show/:id/:companyId/:branchId"   element={!loadingTenant && <ShowInvoiceReturns/> } />
              <Route  
                path=":tenant/quotes/edit/:id"  
                element={  <EditQuote /> } 
                />
              <Route  
              path=":tenant/quotes/create"  
              element={  
                  <CreateQuote 
                      tenantId = {tenantId}
                      tenantUsername ={tenantUsername}
                      companySelected = {companySelected}
                      branchSelected = {branchSelected}
                  /> } 
              />
              <Route path=":tenant/quotes"   element={<ManageQuotes/> } />
              <Route path=":tenant/quotes/show/:id/:companyId/:branchId"   element={!loadingTenant && <ShowQuote/> } />

              <Route path=":tenant/products-home" element={<ProductsHome/>} />
              <Route path=":tenant/products" element={<Products/>} />
              <Route path=":tenant/products/add" element={<CreateProduct/>} />
              <Route path=":tenant/customers" element={<Customers/>} />
              <Route path=":tenant/customers/add" element={<CreateCustomer/>} />
              <Route path=":tenant/customers/show/:id/:companyId/:branchId" element={<ShowCustomer/>} />
              <Route path=":tenant/salesmans" element={<Salesmans/>} />
              <Route path=":tenant/salesmans/add" element={<CreateSalesman/>} />
              <Route path=":tenant/salesmans/edit/:id" element={<EditSalesman/>} />
              {/* <Route path=":tenant/sales/salesmans/show/:id/:companyId/:branchId" element={<ShowCustomer/>} /> */}
              <Route path=":tenant/transactions" element={<Transactions/>} />
              <Route path=":tenant/reports" element={<Reports/>} />
              <Route  path=":tenant/logout"  element={ <Logout/> } />
              <Route path="/notfound"  errorElement={<NotFound/> }  />
              <Route path="/*"  errorElement={<NotFound/> }  />
          </Routes>
        </div>
        </div>
      </div>
      <Footer/>
    </div> : <MwSpinner/>
  );


}

export default withTenant(withGuard(App));
