import  { useEffect, useState } from 'react'
import axios from 'axios';
import InvListBasic from './InvListBasic';
import { useCurrent } from "../../hooks";
import CardHeader from '../card/CardHeader';

const InvListWidget = () => {

    const [invoices,setInvoices] = useState();
    const [loading,setLoading] = useState(true);
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();

    const invoicesHandler = async () => {
        const response = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
        setInvoices(response?.data.data);
        setLoading(false);
        
    }

    useEffect(() => {
        invoicesHandler();
    },[]);

    return (
        <>
            <CardHeader>احدث الفواتير</CardHeader>
            <div className='rounded-lg bg-slate-50 h-60 overflow-y-auto flex flex-col justify-center'>
                <InvListBasic withBorder={false} withSummary={true} loading={loading} data={invoices}/>
            </div>
        </>
    )
}

export default InvListWidget