import { useEffect, useState } from "react";
import {TbFileInvoice} from 'react-icons/tb'
import {  useParams } from "react-router-dom";
import withGuard from "../../utils/withGuard";
import { useCurrent, useFetch, useInvoice } from "../../hooks";
import SearchInvoice from "../../components/SearchInvoice";
import {BiSearch} from 'react-icons/bi'
import InvListBasicZatca from "../../components/invoice/zatca/InvListBasicZatca";
import ImportExcel from "../../components/invoice/zatca/ImportExcel";


const ManageInvoicesZatca = () => {
    const params = useParams();
    const {SETTING_INVOICE} = useInvoice();
    const [invoices,setInvoices] = useState([]);
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    const [links,setLinks] = useState([]) 
    const [zatacType,setZatcaType] = useState("all")
    const [zatacStatus,setZatcaStatus] = useState("ALL")
    const [url,setUrl] = useState(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/${currentTenantId}/${currentCompanyId}/${currentBranchId}/1`);
    const {data:resultInv,loading:loading,refreshHandler} = useFetch(url);
    const [searchLoading,setSearchLoading] = useState(false);
    const [checkList,setCheckList] = useState([]);


    const invoicesHandler = async (newUrl=url) => {
        setUrl(newUrl)
    }
    
    const onChangeSearch = (newValue) => {
        setLinks(newValue.links)
        setInvoices(newValue.data)
        setSearchLoading(false);
    }
    useEffect(() => {
        if(resultInv){
            setLinks(resultInv.links)
            setInvoices(resultInv.data);
            console.log('invoice data',resultInv);
        }
    },[resultInv]);

    const onChangeTypeHandler = (type) => {
        setZatcaType(type)
        setUrl(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/${currentTenantId}/${currentCompanyId}/${currentBranchId}/1/${type.toLowerCase()}/${zatacStatus.toLowerCase()}`);
    }
    const onChangeStatusHandler = (status) => {
        setZatcaStatus(status)
        setUrl(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/${currentTenantId}/${currentCompanyId}/${currentBranchId}/1/${zatacType.toLowerCase()}/${status.toLowerCase()}`);
    }
    const callbackImported = () => {
        setUrl(url+'/')
    }
    return (
        <>
            <div className=' flex flex-between items-center gap-1  text-sm text-gray-400  '>
                <div className="flex-1 px-2 flex gap-1">
                    <TbFileInvoice size={18} />
                    <span>ادارة اعتماد ومشاركة الفواتير ZATCA </span>
                </div>
                <ImportExcel callbackImported ={callbackImported}/>
            </div>
            <div className='flex justify-between items-center py-2'>
                <div className=" flex gap-1 bg-slate-100 rounded-3xl px-2 py-2 text-sm" >
                    <div onClick={() => onChangeTypeHandler('all')} className={` rounded-3xl  px-3 pt-1 cursor-default hover:font-bold hover:bg-slate-300 ${ zatacType === 'all' ? 'font-bold bg-slate-300' : 'bg-slate-200'} `}  > ALL</div>
                    <div onClick={() => onChangeTypeHandler('simplified')} className={` rounded-3xl  px-3 pt-1 cursor-default hover:font-bold hover:bg-slate-300 ${ zatacType === 'simplified' ? 'font-bold bg-slate-300' : 'bg-slate-200'} `} >Simplified</div>
                    <div onClick={() => onChangeTypeHandler('simplified_debit_note')} className={` rounded-3xl  px-3 pt-1 cursor-default hover:font-bold hover:bg-slate-300 ${ zatacType === 'simplified_debit_note' ? 'font-bold bg-slate-300' : 'bg-slate-200'} `} >Simplified Debit Note</div>
                    <div onClick={() => onChangeTypeHandler('simplified_credit_note')} className={` rounded-3xl  px-3 pt-1 cursor-default hover:font-bold hover:bg-slate-300 ${ zatacType === 'simplified_credit_note' ? 'font-bold bg-slate-300' : 'bg-slate-200'} `} >Simplified Credit Note</div>
                    <div onClick={() => onChangeTypeHandler('standard')} className={` rounded-3xl  px-3 pt-1 cursor-default hover:font-bold hover:bg-slate-300 ${ zatacType === 'standard' ? 'font-bold bg-slate-300' : 'bg-slate-200'} `} >Standard</div>
                    <div onClick={() => onChangeTypeHandler('standard_debit_note')} className={` rounded-3xl  px-3 pt-1 cursor-default hover:font-bold hover:bg-slate-300 ${ zatacType === 'standard_debit_note' ? 'font-bold bg-slate-300' : 'bg-slate-200'} `} >Standard Debit Note</div>
                    <div onClick={() => onChangeTypeHandler('standard_credit_note')} className={` rounded-3xl  px-3 pt-1 cursor-default hover:font-bold hover:bg-slate-300 ${ zatacType === 'standard_credit_note' ? 'font-bold bg-slate-300' : 'bg-slate-200'} `} >Standard Credit Note</div>
                </div>
                <div className="flex gap-1 bg-green-100 rounded-3xl px-2 py-2 text-sm" >
                    <div onClick={() => onChangeStatusHandler('ALL')} className={` rounded-3xl  px-3 pt-1 cursor-default hover:font-bold hover:bg-slate-300 ${ zatacStatus === 'ALL' ? 'font-bold bg-slate-300' : 'bg-slate-200'} `}  > ALL</div>
                    {/* <div onClick={() => onChangeStatusHandler('Reported')} className={` rounded-3xl  px-3 pt-1 cursor-default hover:font-bold hover:bg-slate-300 ${ zatacStatus === 'Reported' ? 'font-bold bg-slate-300' : 'bg-slate-200'} `} >REPORTED</div> */}
                    <div onClick={() => onChangeStatusHandler('PASS')} className={` rounded-3xl  px-3 pt-1 cursor-default hover:font-bold hover:bg-slate-300 ${ zatacStatus === 'PASS' ? 'font-bold bg-slate-300' : 'bg-slate-200'} `} >PASS</div>
                    <div onClick={() => onChangeStatusHandler('NULL')} className={` rounded-3xl  px-3 pt-1 cursor-default hover:font-bold hover:bg-slate-300 ${ zatacStatus === 'NULL' ? 'font-bold bg-slate-300' : 'bg-slate-200'} `} >NOT SENT</div>
                    <div onClick={() => onChangeStatusHandler('WARNING')} className={` rounded-3xl text-red-500  px-3 pt-1 cursor-default hover:font-bold hover:bg-slate-300 ${ zatacStatus === 'WARNING' ? 'font-bold bg-slate-300' : 'bg-slate-200'} `} >WARNING</div>
                </div>
            </div>
            {!SETTING_INVOICE?.ZATCA_LINK && <div className="text-red-700 bg-red-200  border-slate-300  rounded-lg px-3 pt-1 my-2 opacity-75 font-bold text-center"> Hint: Your account is not linked with Zatca. Please contact support team at mobile number 0539611960  </div> }
            <div className="bg-slate-50 text-slate-500  rounded-lg text-xs h-[calc(100vh-300px)]">
                <div className="flex gap-3 pb-5">
                    
                    <InvListBasicZatca checkList = {checkList} setCheckList ={setCheckList} loading={loading || searchLoading} links={links} refreshHandler={refreshHandler} paginateHandler = {invoicesHandler} url={url} data={invoices}/> 
                    <div className="w-56 ">
                        <div className="flex gap-1 py-2">
                            <BiSearch size={16}/>
                            <span>أبحث عن فاتورة</span>
                        </div>
                        {/* <hr className="my-2 pb-2"/> */}
                        <SearchInvoice className='bg-white' withFullResults={true} setLoadingCallback={setSearchLoading} onChangeSearch={onChangeSearch} isOpen={false} withFilter={true} />
                    </div> 
                </div>
            </div>
        </>
    )
}

export default withGuard(ManageInvoicesZatca)