import React,{useState} from 'react'
import { MwButton,MwModal,MwSpinnerButton } from '../../ui'
import WarningModal from './WarningModal';

const InvListBasicZatcaItem = ({
    item,
    index,
    withSummary,
    formatter,
    dataLength,
    zatcaLoading,
    setZatcaLoading,
    zatcaSubmited,
    zatcaInvId,
    sendToZatca,
    checkList,
    setCheckList
}) => {
    const [showWarningModal,setShowWarningModal] = useState(false);
    const [warningList,setWarningList] = useState([]);

    const onShowWarning = (warning) => {
        setShowWarningModal(true);
        setWarningList(warning)
    }
    const _zatcaValidate = item?.zatca?.zatca_response ? JSON.parse(item?.zatca?.zatca_response)?.reporting?.validationResults : null;
    const _zatcaWarningErrorList = item.zatca?.zatca_status_final?.trim() === 'WARNING' ? (_zatcaValidate?.warningMessages) : (_zatcaValidate?.errorMessages)
    const onChangeCheck = (item) =>{
        const index = checkList.findIndex( e => e.id === item.id);
        // if(checkList.includes(itemId)){
        if (index > -1) { // only splice array when item is found
            checkList.splice(index, 1); // 2nd parameter means remove one item only
            
        }else{
            checkList.push(item)
        }
        setCheckList([...checkList])
        console.log(checkList)
    }
    return (
        <>
            {
                warningList.length > 0 && 
                <MwModal title='Warning'   onShow={showWarningModal} setOnShow={setShowWarningModal} >
                    <WarningModal warningList={warningList}/>
                </MwModal>
            }
            <div key={item.id}>
                <li className={`  flex gap-2 justify-between p-2 text-xs  hover:bg-indigo-50 hover:font-bold ${ dataLength !== index+1 && ' border-b' } `}>
                        <div className='w-16   flex justify-center items-center'>{item?.zatca?.branch_code} </div>
                        <div className='w-24   flex justify-center items-center'>{item.inv_no} </div>
                        <div className='flex-1 flex justify-between items-center'>
                            <span>
                                {item.customer_name || 'عميل عام'}
                            </span>
                        </div>
                        
                        <div className='w-24  flex  items-center justify-center '>{ formatter.format(item.net_amount) } </div>
                        
                        { !withSummary && <div className='w-32 px-1 flex  items-center justify-center '>{item.inv_date.substring(0,16)}</div> }

                        <div className={`w-36 flex flex-col gap-1 items-center justify-center mx-2 `}>
                            <span className='w-full bg-slate-200 text-center   px-2   pt-1 font-bold rounded-lg'>{item?.zatca?.zatca_inv_type}</span> 
                        </div>
                        <div className={` w-24 flex flex-col gap-1 items-center justify-center  `}>
                            {
                                (item.zatca?.zatca_status === 'CLEARED' || item.zatca?.zatca_status === 'REPORTED') ? 
                                <span className='w-full text-center  px-2 pt-1 font-bold rounded-lg bg-green-200'>{item?.zatca?.zatca_status}</span> :
                                <span className='w-full bg-slate-200 text-center   px-2  pt-1 font-bold rounded-lg'>{item?.zatca?.zatca_status}</span> 
                            }
                            { _zatcaValidate &&  <span onClick={()=>onShowWarning(_zatcaWarningErrorList)} className={`w-full  text-center   px-2  pt-1 font-bold rounded-lg cursor-default ${ item.zatca?.zatca_status_final?.trim() === 'PASS' ? ' bg-green-200 text-green-600 ' : ' bg-red-200 text-red-600 ' }`}>{item.zatca?.zatca_status_final}</span>  }
                            
                        </div>

                        <div className='flex-1 px-2 flex gap-3 justify-center items-center'>
                            {  (!item.zatca?.zatca_status || item.zatca?.zatca_status === "WARNING" ) ? ( (zatcaLoading && item.id === zatcaInvId ) ? <MwSpinnerButton label='Sending to ZATCA...' /> : (zatcaSubmited  && item.id === zatcaInvId ) && <span className='bg-green-200 text-green-600'>تم الإرسال</span> ) : <span className='w-full text-center  px-2 pt-1 font-bold rounded-lg bg-green-200'>SENDED</span>  }
                            {/* { (item.zatca?.zatca_status === 'CLEARED' || item.zatca?.zatca_status === 'REPORTED') && <MwButton type='saveBtn'  >QR الكيو ار</MwButton>} */}
                            {/* { (item.zatca?.zatca_status === 'CLEARED') && <MwButton type='secondary' size="sm" inGroup={true} >Cleared xml invoice</MwButton>} */}
                        </div>
                        <div className='px-3'><input type='checkbox' onChange={()=>onChangeCheck(item)} checked={checkList.findIndex( e => e.id === item.id) > -1}  /></div>
                </li>
            </div>
        </>
    )
}

export default InvListBasicZatcaItem