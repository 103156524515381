import SearchInvoice from './SearchInvoice';
import SearchCustomer from './SearchCustomer';
import SearchQuote from './quote/SearchQuote';



const SearchBar = () => {

    
    return (
        <div className='relative flex gap-1 justify-between items-center text-gray-400 text-xs pb-2 bg-slate-50 p-2 mb-1 rounded-lg'>
                    <div className='flex-1 flex flex-col md:flex-row gap-1'>
                        <SearchCustomer/>
                        <SearchInvoice/>
                        <SearchQuote/>
                        {/* <div className='flex-1'>
                            <MwButton classNameCustom='w-full' inGroup={true} type='saveBtn'  ><MdSupportAgent size={20}/> الدعم الفني المباشر </MwButton>
                        </div> */}
                    </div>
                </div>
    )
}

export default SearchBar